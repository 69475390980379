import React from 'react';
import { Link } from 'react-router-dom'
import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import wrongImage from '../../assets/images/slider/wrong.png';

const NftNotFound = props => {

    return  (
        <section className="tf-slider slider">
            <Swiper
                modules={[Navigation,  Scrollbar, A11y ]}
                    spaceBetween={0}
                    slidesPerView={1}
                    scrollbar={{ draggable: false }}
                >
                {
                    <SwiperSlide>
                        <SliderItem/>
                    </SwiperSlide>
                }
            </Swiper>
        </section>
    )
};

const SliderItem = props => (
    <div className="swiper-container ">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">
                    <div className="overlay"></div>
                    <div className="slider-inner flex home-1">
                        <div className="slider-content">
                            <h1 className="heading">Sorry, This NFT is Not Available !</h1>
                            <p className="sub-heading">Please Check it and try again later</p>
                            <div className="button-slider">
                                <a href="https://mutan.one"
                                    className="sc-button btn-bordered-white style letter "><span>Build NFT Page</span></a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
    
)

export default NftNotFound