import React from 'react';
import Header from '../components/header/Header';
import Slider01 from '../components/slider/Slider01';

const Home01 = ({connectWallet, account, dataNft, signer, checkNFT, currentChainId, networkId}) => {
  return <div className='home-1'>
      <Header connectWallet={connectWallet} account={account} dataNft={dataNft} currentChainId={currentChainId} networkId={networkId}/>
      <Slider01 account={account} dataNft={dataNft} signer={signer} checkNFT={checkNFT} currentChainId={currentChainId} networkId={networkId} connectWallet={connectWallet} />
  </div>;
};

export default Home01;
