import Home01 from "./Home01";
import Home02 from "./Home02";
import Wrongnetwork from "./Wrongnetwork";
import Mynft from './Mynft'
import Allnft from './Allnft'

const routes = [
    { path: '/', component: Home01},
    { path: '/home-02', component: Home02},
    { path: '/wrongnetwork', component: Wrongnetwork},
    { path: '/my-nft', component: Mynft},
    { path: '/all-nft', component: Allnft}
]

export default routes;