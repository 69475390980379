import React from 'react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import CollectionABI from "../../../config/abis/collection.json";
import SoulboundABI from "../../../config/abis/soulbound.json";
import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'

const Itemmynft = props => {
    const [listNft, setListNft] = useState([])
    const [sbPrice, setSBPrice] = useState(0)

    useEffect(() => {
        checkMyNft()
    }, [])

    async function checkMyNft(){
        if (props.provider) {
            let contract = new ethers.Contract(props.dataNft.address, CollectionABI[props.networkId].abi, props.signer);
            let sbAddress = null;
            let sbContract = null;
            try {
                sbAddress = await contract.soulboundAddr().catch((e) => "0x0000000000000000000000000000000000000000")
            } catch (e) {
                sbAddress = "0x0000000000000000000000000000000000000000"
            }
            // CHECK IF HAS SOULBOUND
            if (sbAddress!="0x0000000000000000000000000000000000000000") {
                sbContract = new ethers.Contract(sbAddress, SoulboundABI[props.networkId].abi, props.signer)
                let _sbPrice = await sbContract.mintPrice();
                setSBPrice(_sbPrice/1e18);
            }
            
            // ORIGINAL NFT
            let balance  = await contract.balanceOf(props.account)
            balance = ethers.BigNumber.from(balance).toNumber()
            // console.log(contract);
            let result = [];
            let colName = await contract.name();

            for (let i=0; i<balance; i++) {
                let item = await contract.tokenOfOwnerByIndex(props.account, i);
                let itemId = ethers.BigNumber.from(item).toNumber();
                let urlMetaData = props.dataNft.baseURI + itemId + '.json';
                let object = {}
                await fetch(urlMetaData).then(res => res.json()).then(res => {
                    res.image = res.image.replace("ipfs://", props.gateway)
                    res.tokenId = itemId
                    object = res
                }).catch((e) => {
                    object.image = 'https://lpm.ulm.ac.id/image/desain/empty.jpg'
                    console.error(e)
                })
                object.tokenId = itemId
                object.name = colName.toUpperCase()
                object.address = contract.address
                object.tag = 'Original'
                object.sbAddress = sbAddress
                result.push(object)
            }

            // SOULBOUND NFT
            if (sbContract) {
                let sbBalance  = await sbContract.balanceOf(props.account)
                sbBalance = ethers.BigNumber.from(sbBalance).toNumber()
                let sbName = await sbContract.name();

                for (let i=0; i<sbBalance; i++) {
                    let item = await sbContract.tokenOfOwnerByIndex(props.account, i);
                    let itemId = ethers.BigNumber.from(item).toNumber();
                    let urlMetaData = props.dataNft.baseURI + itemId + '.json';
                    let object = {}
                    await fetch(urlMetaData).then(res => res.json()).then(res => {
                        res.image = res.image.replace("ipfs://", props.gateway)
                        res.tokenId = itemId
                        object = res
                    }).catch((e) => {
                        object.image = 'https://lpm.ulm.ac.id/image/desain/empty.jpg'
                        console.error(e)
                    })
                    object.tokenId = itemId
                    object.name = sbName.toUpperCase()
                    object.address = sbContract.address
                    object.tag = 'Soulbound'
                    result.push(object)
                }                
            }
            setListNft(result)
        }
    }

    function createSBDialog(data) {
        let page = `<img src=${data.image} width="100"/><br/>
        Token Id: ${data.tokenId}<br/>
        Mint Price: <b>${sbPrice} ONE</b>`
        swal.fire({
            title: 'Soulbound Confirmation',
            html: page,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Confirm',
            confirmButtonAriaLabel: 'Confirm',
            cancelButtonText: 'Cancel',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then((confirmation) => {
            if (confirmation.isConfirmed) {
                createSBConfirm(data.tokenId);
            }
        })
    }
    async function createSBConfirm(tokenId) {
        if (window.ethereum) {
            let contract = new ethers.Contract(props.dataNft.address, CollectionABI[props.networkId].abi, props.signer);
            let sbAddress = await contract.soulboundAddr().catch((e) => "0x0000000000000000000000000000000000000000")
            let sbContract = null;
            if (sbAddress != "0x0000000000000000000000000000000000000000") {
                swal.fire({
                    title: "Soulbound NFT",
                    html: `Waiting Confirmation....`,
                    allowEscapeKey: false,
                    allowOutsideClick: false,          
                    didOpen: () => {
                        swal.showLoading()
                    }            
                })
        
                sbContract = new ethers.Contract(sbAddress, SoulboundABI[props.networkId].abi, props.signer)
                let price = await sbContract.mintPrice()

                let nftTxn = await sbContract.mint(tokenId, {value: price}).catch(e => e);
                try {
                    await nftTxn.wait();
                    swal.fire(
                        'Success!',
                        'Success Make Soulbound',
                        'success'
                    )
                    checkMyNft();
                } catch (e) {
                    swal.fire(
                        'Failed!',
                        e,
                        'danger'
                    )                    
                }
            }
        }        
    }

    return (
        <section className="tf-section auctions-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className='row' style={{"marginTop": "2em"}}>
                            {
                                listNft.map((item, index) => {
                                    return (
                                        <div className="col-md-3" key={index}>
                                            <LiveAutionsItem item={item} address={props.dataNft.address} soulbound={createSBDialog}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const LiveAutionsItem = props => (
    <div className="slider-item">
        <div className="sc-product-item style-6">
            <div className="product-img">
                <img src={props.item.image} alt="NFT Image" width={100} />
                <label>{props.item.tag}</label>
            </div>
            <div className="product-content">
                <h5 className="title">{props.item.name}</h5>
                <div className="product-author flex">
                    <div className="infor">
                        <div className="author-name">Token ID : {props.item.tokenId}</div>
                        <br/>
                        {
                            props.item.tag == 'Original' ? (
                                <div>
                                    <button className='btn btn-primary'>
                                        Transfer
                                    </button>
                                    &nbsp;

                                    {
                                        props.item.sbAddress != "0x0000000000000000000000000000000000000000" ? (
                                            <button className='btn btn-primary' onClick={() => props.soulbound(props.item)}>
                                                Soulbound
                                            </button>
                                        ) : (<></>)
                                    }
                                </div>
                            ) : (<></>)
                        }
                        {/* <a href={'https://tofunft.com/nft/harmony/' + props.address + '/' + props.item.tokenId} title="Send / Transfer" className='src-button style letter'>Transfer</a> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Itemmynft;
