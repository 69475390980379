
import './App.css';
import { Routes , Route } from 'react-router-dom'
import routes from './pages/router'
import { ethers } from 'ethers';
import network from "./config/networks.json"
import { useState, useEffect } from 'react';
import WebsiteABI from "./config/abis/builder.json";
import CollectionABI from "./config/abis/collection.json";
import ReactLoading from 'react-loading';
import NftNotFound from './components/content/NFTNotFound';

const gateway = "https://img.tofunft.com/ipfs/";
const preferChainId = "1666600000";

function App() {
    const [loading, setLoading] = useState(true);
    const [loadSetting, setLoadSetting] = useState(false);
    const [provider, setProvider] = useState(null);
    const [account, setAccount] = useState(null);
    const [currentChainId, setCurrentChainId] = useState(null);
    const [signer, setSigner] = useState(null);
    const [dataNFT, setDataNFT] = useState(null);

    useEffect(() => {
        getNetwork()
    }, [])

    useEffect(() => {
        if (provider!==null) {
            checkAccount();
        }
    }, [provider, currentChainId])

    useEffect(() => {
        if (signer) {
            checkNFT()
        }
    }, [signer])

    async function connectWallet() {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log("Wallet connected")
            checkAccount();
            return true;
        } else {
            alert('Can\'t Connect Wallet')
            return false;
        }
    }

    async function checkAccount() {
        if (window.ethereum) {
            if (currentChainId == network.networkId) {
                let accounts = await provider.listAccounts()
                console.log("LIST ACCOUNT", accounts)
                if (accounts.length > 0) {
                    setAccount(accounts[0])
                    /* SET SIGNER */
                    let signer = provider.getSigner(accounts[0]);
                    setSigner(signer);
                }    
                return true;
            } else {
                let signer = provider.getSigner("0x0000000000000000000000000000000000000000");
                setSigner(signer);
                return false;
            }
        } else {
            return false;
        }
    }

    async function checkNFT() {
        if (provider && signer) {
            setLoading(true);
            let nftDomain = window.location.hostname;
            // let nftDomain = "hack.mutan.one";

            const websiteContract = new ethers.Contract(WebsiteABI[preferChainId].address, WebsiteABI[preferChainId].abi, signer);
            const collectionNFT = await websiteContract.listDomain(nftDomain);

            if (collectionNFT.nftAddress != "0x0000000000000000000000000000000000000000") {
                /* SET WEBSITE SETTING */
                const nftContract = new ethers.Contract(collectionNFT.nftAddress, CollectionABI[preferChainId].abi, signer);
                const name = await nftContract.name();
                const supply = await nftContract.MAX_SUPPLY();
                const minted = await nftContract.totalSupply();
                const price = await nftContract.mintPrice();
                let baseURI = await nftContract.defaultURI();
                baseURI = !baseURI ? "ipfs://QmSLucAumCAztbu7MiZTHdEAaXNLwevSmQC9cfe91VYzLv/" : baseURI;
                console.log("BASEURI", baseURI)
                baseURI = baseURI.replace("ipfs://", gateway);
                let showcase = await fetch(baseURI + "1.json").then(text => text.json()).catch((e) => null);
                if (showcase) {
                    console.log(showcase);
                    if (showcase.image) {
                        showcase.image = showcase.image.replace("ipfs://", gateway)
                        // setCover(showcase.image.replace("ipfs://", gateway));
                    }
                }

                setLoading(false);
                setLoadSetting(true);
                let nftObject = {
                    name: name,
                    supply: supply,
                    minted: minted,
                    price: price,
                    baseURI: baseURI,
                    cover: showcase?showcase.image:"",
                    address: collectionNFT.nftAddress
                }
                setDataNFT(nftObject);
                window.document.title = `Mint - ${name}`

            } else {
                setLoading(false);
                setLoadSetting(false);
                // setNoBrand(true);
            }
        } else {
            setLoading(false);
            setLoadSetting(false);
            // setNoBrand(true);
        }
    }

    async function getNetwork()  {
        if (window.ethereum) {
            // await window.ethereum.request({ method: 'eth_requestAccounts' });
            let provider = new ethers.providers.Web3Provider(window.ethereum);

            let { chainId } = await provider.getNetwork();

            /* IF NETWORK WRONG */
            if (!network[chainId]) {
                provider = new ethers.providers.JsonRpcProvider(network[preferChainId].rpcUrl);
            } else {
                // CHECK IF NETWORK NOT PROVIDED
                if (chainId != preferChainId) {
                    console.log("CHAIN TIDAK SESUAI")
                }
            }
            let signer = provider.getSigner("0x0000000000000000000000000000000000000000");
            setSigner(signer);
            setProvider(provider);
            setCurrentChainId(chainId);
        } else {
            let provider = new ethers.providers.JsonRpcProvider(network[preferChainId].rpcUrl);
            let signer = provider.getSigner("0x0000000000000000000000000000000000000000");
            setSigner(signer);
            setProvider(provider);
        }
    }

    return loading ? (
        <>
            <div className='row'>
                <div className='container'>
                    <center>
                        <br/>
                        <div className='row'>
                            <div className='col-12'>
                                <ReactLoading type="cylon" height={100} width={200} />
                                <br/>
                                <br/>
                            </div>
                            <div className='col-12'>
                                <span>Loading Setting...</span>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </>
    ) : !loadSetting ? (
        <>
            <NftNotFound/>
        </>
    ) : (
        <Routes>
            {
                routes.map((data,index) => {
                    const Page = data.component;
                    return (
                        <Route
                            exact={true}
                            path={data.path}
                            key={index}
                            element={<Page
                                provider={provider}
                                connectWallet={connectWallet}
                                account={account}
                                gateway={gateway}
                                dataNft={dataNFT}
                                signer={signer}
                                checkNFT={checkNFT}
                                currentChainId={currentChainId}
                                networkId={preferChainId}
                            />}>
                        </Route>
                    )
                })
            }
      </Routes>
    );
}

export default App;
