import React from 'react';
import Header from '../components/header/Header';
import Wrong from '../components/content/Wrongnetwork';
import dataSlider from '../assets/fake-data/dataSlider';
import Footer from '../components/footer/FooterStyle2';


const Wrongnetwork = () => {
  return <div className='home-1'>
      <Header />
      <Wrong data={dataSlider} />
      <Footer />
  </div>;
};

export default Wrongnetwork;
