import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import LiveAutions from '../components/layouts/item/Itemmynft';
import dataPopularCollection from '../assets/fake-data/dataPopularCollection';

const Mynft = ({connectWallet, account, dataNft, provider, signer, networkId, gateway}) => {
  return (
    <div className='item'>
        <Header connectWallet={connectWallet} account={account} dataNft={dataNft} />
        <section className="fl-page-title">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-title-inner flex">
                            <div className="page-title-heading">
                                <h2 className="heading">My NFT</h2>
                            </div>
                            <div className="breadcrumbs">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Item</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {
            dataNft !== null ? (
                <LiveAutions data={dataPopularCollection} connectWallet={connectWallet} account={account} dataNft={dataNft} provider={provider} signer={signer} gateway={gateway} networkId={networkId} />
            ) : <></>
        }
    </div>
  );
};

export default Mynft;
