import React from 'react';
import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CollectionABI from "../../config/abis/collection.json";
import { ethers } from 'ethers';
import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'

const Slider01 = props => {
    const data = props.dataNft

    async function mintHandler() {
        swal.fire({
            title: "Minting NFT",
            html: `Waiting Confirmation....`,
            allowEscapeKey: false,
            allowOutsideClick: false,          
            didOpen: () => {
                swal.showLoading()
            }            
        })
        const nftContract = new ethers.Contract(data.address, CollectionABI[props.networkId].abi, props.signer);
        let nftTxn = await nftContract.mint({value: ethers.utils.parseEther((data.price/1e18).toString())});
        await nftTxn.wait();
        // console.log(nftTxn.hash);

        swal.fire(
            'Success!',
            'You Got Your NFT',
            'success'
        )

        props.checkNFT();
    }

    return  (
        <section className="tf-slider slider">
            <Swiper
                modules={[Navigation,  Scrollbar, A11y ]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    scrollbar={{ draggable: true }}
                >
                <SwiperSlide>
                    {
                        props.dataNft !== null ? (
                            <SliderItem item={{
                                title: `MINT ${data.name}`,
                                description: `Price ${data.price/1e18} ONE`,
                                smallText: `Minted: ${data.minted} / ${data.supply}`,
                                price: data.price/1e18,
                                img: data.cover,
                                address: data.address,
                            }} mintHandler={mintHandler}
                                networkId={props.networkId}
                                currentChainId={props.currentChainId}
                                connectWallet={props.connectWallet}
                                account={props.account} />
                        ) : <></>
                    }
                </SwiperSlide>
            </Swiper>
        </section>
    )
};

const SliderItem = props => (
    <div className="swiper-container ">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">
                    <div className="overlay"></div>
                    <div className="slider-inner flex home-1">
                        <div className="slider-content">
                            <h1 className="heading">{props.item.title.toUpperCase()}</h1>
                            <p className="sub-heading" style={{marginBottom: 5}}>{props.item.description} | {props.item.smallText}</p>
                            <br/>
                            <div className="button-slider">
                                {
                                    props.networkId.toString() !== props.currentChainId.toString() ? (
                                        <button
                                            onClick={() => {}}
                                            className="sc-button btn-bordered-white style">
                                            <span>{"WRONG NETWORK"}</span>
                                        </button>
                                    ) : props.account === null ? (
                                        <button
                                            onClick={props.connectWallet}
                                            className="sc-button btn-bordered-white style-2">
                                            <span>CONNECT WALLET</span>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={props.mintHandler}
                                            className="sc-button btn-bordered-white style letter ">
                                            <span>MINT NOW</span>
                                        </button>
                                    )
                                }
                                <br/><br/>
                                <small>Smart Contract Address : <a href={"https://explorer.harmony.one/address/" + props.item.address}>{props.item.address}</a></small>
                            </div>
                        </div>
                        <div className="slider-img">
                            {
                                props.item.img ? (
                                    <div className="img-home-1">
                                        <img width={500} src={props.item.img} alt="Bidzen"/>
                                    </div>                                    
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
)

export default Slider01;
