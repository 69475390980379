import React , { useRef , useEffect } from 'react';
import { Link } from 'react-router-dom'
import DarkMode from "./DarkMode"
import networks from "../../config/networks.json"

import icon from '../../assets/images/icon/connect-wallet.svg'

const Header = ({connectWallet, account, dataNft, currentChainId, networkId}) => {
    const headerRef = useRef (null)
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        console.log(networks[currentChainId])
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        
        scrollTop >= 100 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 120 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    var logoStyle = {
        padding: "26px 24px 26px 13px"
    };

      
    return <div>
      {/* <TopBar /> */}
      <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        
                        
                        <div id="site-header-inner" className="container">
                        
                            <div className="row">
                                
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-6">
                                    <div className='flex'>
                                    <div id="site-logo" className="clearfix">
                                        <div id="site-logo-inner">
                                            <Link to="/" rel="home" className="main-logo">
                                                <h4 className='menu-item' style={logoStyle}>{dataNft ? dataNft.name.toUpperCase() : "Site Name"}</h4>
                                            </Link>
                                        </div>
                                    </div>
                                    <nav id="main-nav" className="main-nav" ref={menuLeft}>
                                        <ul id="menu-primary-menu" className="menu pull-right">
                                            <li className={`menu-item` }>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className={`menu-item` }>
                                                <Link to="/my-nft">My NFT</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="button-connect-wallet">
                                        {
                                            account !== null ? (
                                                <a href="#" onClick={() => connectWallet()} className="sc-button wallet style-2 mt-4">
                                                    <img src={icon} alt="icon" />
                                                    <span>{account}</span>
                                                </a>
                                            ) : (networkId != currentChainId) || (!networks[currentChainId]) ? (
                                                <a href="#" onClick={() => connectWallet()} className="sc-button wallet style-2 mt-4" style={{"background": "red"}}>
                                                    <img src={icon} alt="icon" />
                                                    <span>WRONG NETWORK</span>
                                                </a>
                                            ) : (
                                                <a href="#" onClick={() => connectWallet()} className="sc-button wallet style-2 mt-4">
                                                    <img src={icon} alt="icon" />
                                                    <span>Connect Wallet</span>
                                                </a>
                                            )
                                        }
                                    </div>

                                    <DarkMode />
                                    <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
  </div>;
};

export default Header;
