import React from 'react';
import { Link } from 'react-router-dom'
import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import wrongImage from '../../assets/images/slider/wrong.png';

const Wrongnetwork = props => {
    const data = props.data
    return  (
        <section className="tf-slider slider">
            <Swiper
                modules={[Navigation,  Scrollbar, A11y ]}
                    spaceBetween={0}
                    slidesPerView={1}
                    scrollbar={{ draggable: false }}
                >
                {
                    data.map((item, index) => (
                        <SwiperSlide key={index}>
                            <SliderItem item={item} />
                        </SwiperSlide>
                        
                    ))
                }
            </Swiper>
        </section>
    )
};

const SliderItem = props => (
    <div className="swiper-container ">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">
                    <div className="overlay"></div>
                    <div className="slider-inner flex home-1">
                        <div className="slider-content">
                            <h1 className="heading">Sorry, You Are Wrong Network !</h1>
                            <p className="sub-heading">Please Check Network Before, Then Refresh This Page</p>
                            <div className="button-slider">
                                <Link to="/explore-01"
                                    className="sc-button btn-bordered-white style letter "><span>Change Network</span></Link>
                            </div>
                        </div>
                        <div className="slider-img">
                            <div className="img-home-1 mutan-padding-top"><img src={wrongImage} alt="Bidzen"/></div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
    
)

export default Wrongnetwork